jQuery.noConflict();
(function( $ ) {
  $(function() {

   

    // var loader = $('#overlay-loader');
        
    $('img').load(function(){
      $(this).css('background','none');
    });

    $(document).on('submit', 'form#timelineCreate', function (e) {  
          
      // $(document).find('#overlay-loader').css('display', 'block');

      e.preventDefault();

      var formData = $(this).serialize();
      $('#genericModal').modal('toggle');
      $.ajax({
        type : "POST",
        dataType : "json",
        url : amoAjax.ajaxurl,
        data : {
          data : formData,
          nonce : $('meta[name=amo-csrf]').attr("content"),
          action : $(this).attr('action')
        },
      }).done(function(data){

        if (data.code == 200) {
          toastr.success(data.message, 'Success');
        } else {
          toastr.error(data.message, 'Error');
        }

        $('#'+data.type+'-footer').find('select option:first').after($('<option>', {
            value: data.post_id,
            text: data.title,
            selected: 'selected'
        }));

        // $('#overlay-loader').css('display', 'none');

      });

    });


    

    // var loader = $('#pageloader');
    $('#orderModal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget) // Button that triggered the modal
      var dataID = button.data('id') // Extract info from data-* attributes
      var modal = $(this)
      modal.find('.place-order').attr('data-id',dataID);
    })


    $(document.body).on('click', '#menu-footer-legal .terms', function(e){
        e.preventDefault();
        $('#termsModal').modal('toggle');
    });

    $('#amo-admin-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var tab = $(e.target).data("tab-id");
      updateTabCookie(tab);
    });



    function updateTabCookie(tab)
    {
      $.ajax({
          async: true,
          type : "POST",
          dataType: "json",
          url : amoAjax.ajaxurl,
          data : {
              action : "amo_memat_update_tab_selection",
              nonce : $('meta[name="amo-csrf"]').attr('content'),
              tab_id : tab,
          },
      }).done(function(data){
        console.log(data);
      });
    }

    // $(document).ajaxSend(function(){
    //   showLoader();
    // });
    // $(document).ajaxComplete(function(){
    //   hideLoader();
    // });

    function showLoader()
    {
        $('html, body').css("cursor", "wait"); 
        $('#amo-background-overlay').show();
    }

    function hideLoader()
    {
        $('html, body').css("cursor", "auto"); 
        $('#amo-background-overlay').hide();
    }

  });
})(jQuery);

